import logo from './Smart_Cities_logo_3.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <main className="App-main">
        <p>
            ТОО "Умные города" создана в 2019 году.<br />
            Компания работает в сфере управления<br />
            IT-инфраструктурой, а также как единый оператор<br />
            различных сервисов и бэк-офис процессов.
            <br />
            Наши продукты: <a
            className="App-link"
            href="https://aitu.cloud/"
            target="_blank"
            rel="noopener noreferrer"
          >Aitu.Cloud</a> 
        </p>
      </main>
      <footer>
        Наши контакты:<br />
        Казахстан, Астана, 010000<br />
        район Есиль, улица Сыганак, д. 60/2<br />
        Блок «О» на 15-ом этаже. МФК «Абу Даби Плаза»<br />
        <a href="tel:+77015264692">+7 701 526 46 92</a><br />
        <a href="mailto:mikhail.minkin@scity.pro">mikhail.minkin@scity.pro</a>
      </footer>
    </div>
  );
}

export default App;
